import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockTransactions } from "../data/mockData";

const ListTable = ({ data = mockTransactions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
        {data.map((transaction, i) => (
            <Box
              key={`${transaction.invoiceId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.invoiceId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.company}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.dateAdded}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.amount}
              </Box>
            </Box>
        ))}
    </Box>
  );
};

export default ListTable;
