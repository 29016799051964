import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";

import Leads from "./scenes/leads";
import NewLead from "./scenes/newlead";
import Lead from "./scenes/lead";
import NewDemo from "./scenes/newdemo";

import Templates from "./scenes/templts";
import AddTemplate from "./scenes/addtemplate";
import DemoSites from "./scenes/demos";

import Team from "./scenes/team";
import Invoices from "./scenes/invoices";

import Analytics from "./scenes/analytics";

// import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <Routes>
              <Route path="/" element={<Dashboard />} />

              {/* <Route path="/leads" element={<Team />} /> */}
              <Route path="/leads" element={<Leads />} />
              <Route path="/leads/detail" element={<Lead />} />
              <Route path="/leads/new" element={<NewLead />} />
              <Route path="/leads/demo" element={<NewDemo />} />

              <Route path="/templates" element={<Templates />} />
              <Route path="/templates/detail" element={<Team />} />
              <Route path="/templates/new" element={<AddTemplate />} />

              <Route path="/demo" element={<DemoSites />} />
              <Route path="/demo/details" element={<Team />} />

              {/* <Route path="/analytics" element={<Analytics />} /> */}

              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
