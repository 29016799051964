import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../components/Header";

import axios from 'axios';

const Leads = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [leads, setLeads] = useState([]);

  let navigate = useNavigate();

  const goTo = (route) => {
    navigate(route);
  }

  const showLead = (leadId) => {
    localStorage.setItem('leadId', leadId);
    navigate('/leads/detail');
  }

  const openLink = (url) => {
    window.open(url, '_blank', 'noreferrer');
  }

  useEffect(() => {
    axios.post('https://ebcrmportal.com/api/leads/')
    .then(response => {
      setLeads(response.data.data);
    })
  }, [])

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "company",
      headerName: "Company Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Industry",
      flex: 1,
    },
    {
      field: "demoId",
      headerName: "Demo Code",
      flex: 1,
    },
    {
      field: "domain",
      headerName: "Demo Site",
      flex: 1,
      renderCell: (row) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
            onClick={() => openLink(row.row.domain)}
          >
            {row.row.domain}
          </Box>
        );
      },
    },
    {
      field: "accessLevel",
      headerName: "Actions",
      flex: 1,
      renderCell: (row) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={colors.greenAccent[600]}
            borderRadius="4px"
            onClick={() => showLead(row.id)}
          >
            <LockOpenOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              Details
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Leads" subtitle="Manage the Leads" />
      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={() => goTo('/leads/new')}
        >
          Add New Lead
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={leads} columns={columns} />
      </Box>
    </Box>
  );
};

export default Leads;
