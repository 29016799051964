import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, TextField, useTheme, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";

const AddTemplate = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  let navigate = useNavigate();

  const handleFormSubmit = (values) => {
    // run api call to add new template
    axios.post('https://ebcrmportal.com/api/templates/add', values)
    .then(response => {
      console.log(response.data.data);
        if (response.data.status === 'success') {
          toast.success(response.data.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        } else {
          toast.warning(response.data.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }
        goTo('/templates');
    })
  };

  const goTo = (route) => {
    navigate(route);
  }

  return (
    <Box m="20px">
      <Header title="CREATE TEMPLATE" subtitle="Create a New Site Template" />

      <Box m="0 0 20px 0">
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={() => goTo('/templates')}
        >
          Return to List of Templates
        </Button>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Market/Company"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                name="companyName"
                error={!!touched.companyName && !!errors.companyName}
                helperText={touched.companyName && errors.companyName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Short Comapny Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.shortName}
                name="shortName"
                error={!!touched.shortName && !!errors.shortName}
                helperText={touched.shortName && errors.shortName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Logo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.logo}
                name="logo"
                error={!!touched.logo && !!errors.logo}
                helperText={touched.logo && errors.logo}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Company Site"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.site}
                name="site"
                error={!!touched.site && !!errors.site}
                helperText={touched.site && errors.site}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Company Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Company Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Company Address 3"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address3}
                name="address3"
                error={!!touched.address3 && !!errors.address3}
                helperText={touched.address3 && errors.address3}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="State"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.state}
                name="state"
                error={!!touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Zip Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zipCode}
                name="zipCode"
                error={!!touched.zipCode && !!errors.zipCode}
                helperText={touched.zipCode && errors.zipCode}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Fax"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fax}
                name="fax"
                error={!!touched.fax && !!errors.fax}
                helperText={touched.fax && errors.fax}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Company Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Domain"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.domain}
                name="domain"
                error={!!touched.domain && !!errors.domain}
                helperText={touched.domain && errors.domain}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="File Format"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fileFormat}
                name="fileFormat"
                error={!!touched.fileFormat && !!errors.fileFormat}
                helperText={touched.fileFormat && errors.fileFormat}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Industry"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.businessType}
                name="businessType"
                select
                error={!!touched.businessType && !!errors.businessType}
                helperText={touched.businessType && errors.businessType}
                sx={{ gridColumn: "span 4" }}
              >
                <MenuItem value={'healthcare'}>Health Care</MenuItem>
                <MenuItem value={'lawfirm'}>Law</MenuItem>
                <MenuItem value={'retail'}>Retail</MenuItem>
                <MenuItem value={'business'}>Other</MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Accept Card Payments?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.isCreditCardEnabled}
                name="isCreditCardEnabled"
                error={!!touched.isCreditCardEnabled && !!errors.isCreditCardEnabled}
                helperText={touched.isCreditCardEnabled && errors.isCreditCardEnabled}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Accept ACH?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.isACHEnabled}
                name="isACHEnabled"
                error={!!touched.isACHEnabled && !!errors.isACHEnabled}
                helperText={touched.isACHEnabled && errors.isACHEnabled}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Allow Card Fees?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.allowCardFee}
                name="allowCardFee"
                error={!!touched.allowCardFee && !!errors.allowCardFee}
                helperText={touched.allowCardFee && errors.allowCardFee}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Credit Card Fees Only?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.enableCCFeeOnly}
                name="enableCCFeeOnly"
                error={!!touched.enableCCFeeOnly && !!errors.enableCCFeeOnly}
                helperText={touched.enableCCFeeOnly && errors.enableCCFeeOnly}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Recurring Payments?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.allowsRecurringPayments}
                name="allowsRecurringPayments"
                error={!!touched.allowsRecurringPayments && !!errors.allowsRecurringPayments}
                helperText={touched.allowsRecurringPayments && errors.allowsRecurringPayments}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Simple Payment Plan?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.usesPaymentPlans}
                name="usesPaymentPlans"
                error={!!touched.usesPaymentPlans && !!errors.usesPaymentPlans}
                helperText={touched.usesPaymentPlans && errors.usesPaymentPlans}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Advanced Payment Plan Creator?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.usesPaymentPlanCreator}
                name="usesPaymentPlanCreator"
                error={!!touched.usesPaymentPlanCreator && !!errors.usesPaymentPlanCreator}
                helperText={touched.usesPaymentPlanCreator && errors.usesPaymentPlanCreator}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Indefinite Payments?"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.allowsIndefinitePayments}
                name="allowsIndefinitePayments"
                error={!!touched.allowsIndefinitePayments && !!errors.allowsIndefinitePayments}
                helperText={touched.allowsIndefinitePayments && errors.allowsIndefinitePayments}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Ask Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.askEmail}
                name="askEmail"
                error={!!touched.askEmail && !!errors.askEmail}
                helperText={touched.askEmail && errors.askEmail}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Ask Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.askPhone}
                name="askPhone"
                error={!!touched.askPhone && !!errors.askPhone}
                helperText={touched.askPhone && errors.askPhone}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Minimum Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.minAmount}
                name="minAmount"
                error={!!touched.minAmount && !!errors.minAmount}
                helperText={touched.minAmount && errors.minAmount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Maximum Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxAmount}
                name="maxAmount"
                error={!!touched.maxAmount && !!errors.maxAmount}
                helperText={touched.maxAmount && errors.maxAmount}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" onClick={() => handleFormSubmit(values)}>
                Create New Template
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  companyName: yup.string().required("required"),
  logo: yup.string().required("required"),
  domain: yup.string().required("required"),
  recurringOptions: yup.string().required("required"),
  minAmount: yup.string().required("required"),
});

const initialValues = {
  companyName: "",
  shortName: "",
  logo: "",
  site: "",
  address: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  zipCode: "",
  phone: "",
  fax: "",
  description: "",
  domain: "",
  fileFormat: "",
  businessType: "",
  isCreditCardEnabled: "",
  isACHEnabled: "",
  allowCardFee: "",
  enableCCFeeOnly: "",
  allowsRecurringPayments: "",
  allowsIndefinitePayments: "",
  recurringOptions: "",
  usesPaymentPlans: "", //simple plan
  usesPaymentPlanCreator: "",
  askEmail: "",
  askPhone: "",
  minAmount: "",
  maxAmount: ""
};

export default AddTemplate;
