import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../components/Header";

import axios from 'axios';

const Templates = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [templates, setTemplates] = useState([]);

  let navigate = useNavigate();

  const goTo = (route) => {
    navigate(route);
  }

  useEffect(() => {
    axios.post('https://ebcrmportal.com/api/templates/')
    .then(response => {
      setTemplates(response.data.data);
    })
  }, [])

  const columns = [
    {
      field: "companyName",
      headerName: "Market/Company Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "companyId",
      headerName: "ID",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "domain",
      headerName: "Domain",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : access === "manager"
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            <LockOpenOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              Details
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Templates" subtitle="Manage Site Templates" />
      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={() => goTo('/templates/new')}
        >
          Add New Market/Template
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={templates} columns={columns} />
      </Box>
    </Box>
  );
};

export default Templates;
