import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ListTable from "../../components/ListTable";
import ProgressCircle from "../../components/ProgressCircle";
import axios from 'axios';

import { mockBarData } from "../../data/mockData";
import { monthKeys as barKeys } from "../../data/mockData";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dashData, setDashData] = useState({
    analytics: {
      volume: {title: "", subtitle: "YTD Volume", progress: "", date: ""},
      count: {title: "", subtitle: "YTD Count", progress: "", date: ""},
      collected: {title: "", subtitle: "YTD Volume", progress: "", date: ""},
      // monthVolume: {title: "$130,991,146.3", subtitle: "Last Month Volume", progress: "1", date: "April 2023"},
      leads: {title: "", subtitle: "YTD Leads", progress: "", date: ""},
    },
    monthKeys: barKeys,
    graphData: {},
    progressBar: "0.00",
    geoData: [],
    listTransactions: [],
    barChartData: mockBarData
  });
  const [configGraph, setConfigGraph] = useState([
    {
      id: "USD",
      color: tokens("dark").greenAccent[500],
      data: [{"x": 0, "y": 0}]
    }
  ]);

  useEffect(() => {
    axios.post('https://ebcrmportal.com/api/', {counter: 1})
    .then(response => {
      setDashData(response.data.data);
      setConfigGraph([
        {
          id: "USD",
          color: tokens("dark").greenAccent[500],
          data: response.data.data.graphData
        }
      ])
    })
    .catch(err => {
      console.log(err);
    })
  }, [])

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to the EveryBill dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports (Unavailable)
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dashData.analytics.volume.title}
            subtitle={dashData.analytics.volume.subtitle}
            progress={dashData.analytics.volume.progress}
            increase={dashData.analytics.volume.date} /* Change increase to as of date */
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dashData.analytics.count.title}
            subtitle={dashData.analytics.count.subtitle}
            progress={dashData.analytics.count.progress}
            increase={dashData.analytics.count.date}
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dashData.analytics.collected.title}
            subtitle={dashData.analytics.collected.subtitle}
            progress={dashData.analytics.collected.progress}
            increase={dashData.analytics.collected.date}
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dashData.analytics.leads.title}
            subtitle={dashData.analytics.leads.subtitle}
            progress={dashData.analytics.leads.progress}
            increase={dashData.analytics.leads.date}
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                YTD Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {dashData.analytics.collected.title}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} graphData={configGraph} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Invoices
            </Typography>
          </Box>
          <ListTable data={dashData.listTransactions} />
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Year Progress
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" progress={dashData.progressBar} />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              $ {dashData.analytics.collected.title} revenue generated
            </Typography>
            <Typography>Includes only raw revenue generated</Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Payments By Type
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} data={dashData.barChartData} barKeys = {dashData.monthKeys} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} data = {dashData.geoData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
