import React from 'react';
import { Box, TextField, MenuItem, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { toast } from 'react-toastify';
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";

const ConfigurationList = ({ title="Demo Site Configuration", configuration, leadData }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const initialValues = configuration;
  let navigate = useNavigate();

  const goTo = (route) => {
    navigate(route);
  }
  
  const handleFormSubmit = (values) => {
    let params = {
      info: leadData,
      settings: values
    }

    axios.post('https://ebcrmportal.com/api/demos/add', params)
    .then(response => {
      if (response.data.status === 'success') {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      } else {
        toast.warning(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      goTo('/leads');
    })
  };

  const checkoutSchema = yup.object().shape({
    fileNameIsCalled: yup.string()
  });

  return (
    <Box width="100%" m="0 30px 20px 30px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              m="20px 0 0 0"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* File Number Details */}
              <TextField
                  fullWidth
                  variant="filled"
                  label="File Naming Convention"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fileNameIsCalled}
                  name="fileNameIsCalled"
                  helperText={touched.fileNameIsCalled && errors.fileNameIsCalled}
                  error={!!touched.fileNameIsCalled && !!errors.fileNameIsCalled}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="Max Length of File Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fileNameMaxLength}
                  name="fileNameMaxLength"
                  helperText={touched.fileNameMaxLength && errors.fileNameMaxLength}
                  error={!!touched.fileNameMaxLength && !!errors.fileNameMaxLength}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="File Number Validator"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fileNumberRegEx}
                  name="fileNumberRegEx"
                  helperText={touched.fileNumberRegEx && errors.fileNumberRegEx}
                  error={!!touched.fileNumberRegEx && !!errors.fileNumberRegEx}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  label="File Number Readable Format"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fileNumberReadableFormat}
                  name="fileNumberReadableFormat"
                  helperText={touched.fileNumberReadableFormat && errors.fileNumberReadableFormat}
                  error={!!touched.fileNumberReadableFormat && !!errors.fileNumberReadableFormat}
                  sx={{ gridColumn: "span 2" }}
                />

                {/* Amount */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Min Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.minAmount}
                  name="minAmount"
                  helperText={touched.minAmount && errors.minAmount}
                  error={!!touched.minAmount && !!errors.minAmount}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="Max Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxAmount}
                  name="maxAmount"
                  helperText={touched.maxAmount && errors.maxAmount}
                  error={!!touched.maxAmount && !!errors.maxAmount}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="Card Minimum Amount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cardMinAmount}
                  name="cardMinAmount"
                  helperText={touched.cardMinAmount && errors.cardMinAmount}
                  error={!!touched.cardMinAmount && !!errors.cardMinAmount}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="Allow Card Fees?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.allowCardFee}
                  name="allowCardFee"
                  select
                  helperText={touched.allowCardFee && errors.allowCardFee}
                  error={!!touched.allowCardFee && !!errors.allowCardFee}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                {/* Card Fees */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Credit Card Fees Only?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.enableCCFeeOnly}
                  name="enableCCFeeOnly"
                  select
                  helperText={touched.enableCCFeeOnly && errors.enableCCFeeOnly}
                  error={!!touched.enableCCFeeOnly && !!errors.enableCCFeeOnly}
                  sx={{ gridColumn: "span 2" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Card Fee Percent"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cardFeePercent}
                  name="cardFeePercent"
                  helperText={touched.cardFeePercent && errors.cardFeePercent}
                  error={!!touched.cardFeePercent && !!errors.cardFeePercent}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  label="Card Flat Fee"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cardFlatFee}
                  name="cardFlatFee"
                  helperText={touched.cardFlatFee && errors.cardFlatFee}
                  error={!!touched.cardFlatFee && !!errors.cardFlatFee}
                  sx={{ gridColumn: "span 1" }}
                />

                {/* Pay Methods */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Accept ACH?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.isAchEnabled}
                  name="isAchEnabled"
                  select
                  helperText={touched.isAchEnabled && errors.isAchEnabled}
                  error={!!touched.isAchEnabled && !!errors.isAchEnabled}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Accept Card Payments?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.isCreditCardEnabled}
                  name="isCreditCardEnabled"
                  select
                  helperText={touched.isCreditCardEnabled && errors.isCreditCardEnabled}
                  error={!!touched.isCreditCardEnabled && !!errors.isCreditCardEnabled}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Accept Checks?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.isCheckDraftEnabled}
                  name="isCheckDraftEnabled"
                  select
                  helperText={touched.isCheckDraftEnabled && errors.isCheckDraftEnabled}
                  error={!!touched.isCheckDraftEnabled && !!errors.isCheckDraftEnabled}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                {/* Pay Options */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Use Payment Plans?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.usesPaymentPlans}
                  name="usesPaymentPlans"
                  select
                  helperText={touched.usesPaymentPlans && errors.usesPaymentPlans}
                  error={!!touched.usesPaymentPlans && !!errors.usesPaymentPlans}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Activate Plan Creator?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.usesPaymentPlanCreator}
                  name="usesPaymentPlanCreator"
                  select
                  helperText={touched.usesPaymentPlanCreator && errors.usesPaymentPlanCreator}
                  error={!!touched.usesPaymentPlanCreator && !!errors.usesPaymentPlanCreator}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Allow Recurring Payments?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.allowsRecurringPayments}
                  name="allowsRecurringPayments"
                  select
                  helperText={touched.allowsRecurringPayments && errors.allowsRecurringPayments}
                  error={!!touched.allowsRecurringPayments && !!errors.allowsRecurringPayments}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Allow Indefinite Payments?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.allowsIndefinitePayments}
                  name="allowsIndefinitePayments"
                  select
                  helperText={touched.allowsIndefinitePayments && errors.allowsIndefinitePayments}
                  error={!!touched.allowsIndefinitePayments && !!errors.allowsIndefinitePayments}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Activate Consumer Portal?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.enableConsumerLogin}
                  name="enableConsumerLogin"
                  select
                  helperText={touched.enableConsumerLogin && errors.enableConsumerLogin}
                  error={!!touched.enableConsumerLogin && !!errors.enableConsumerLogin}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                {/* Recurring Options */}
                {
                  values.allowsRecurringPayments === 1 &&
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Recurring Options"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.recurringOptions}
                    name="recurringOptions"
                    select
                    helperText={touched.recurringOptions && errors.recurringOptions}
                    error={!!touched.recurringOptions && !!errors.recurringOptions}
                    sx={{ gridColumn: "span 3" }}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                    <MenuItem value={'monthly-until-paid-in-full'}>Monthly Indefinite</MenuItem>
                  </TextField>
                }

                {/* Require Basic */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Require Address?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.requireAddress}
                  name="requireAddress"
                  select
                  helperText={touched.requireAddress && errors.requireAddress}
                  error={!!touched.requireAddress && !!errors.requireAddress}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Ask For Phone?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.askForPhone}
                  name="askForPhone"
                  select
                  helperText={touched.askForPhone && errors.askForPhone}
                  error={!!touched.askForPhone && !!errors.askForPhone}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Require Phone?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.requirePhone}
                  name="requirePhone"
                  select
                  helperText={touched.requirePhone && errors.requirePhone}
                  error={!!touched.requirePhone && !!errors.requirePhone}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  variant="filled"
                  label="Ask For Email?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.askForEmail}
                  name="askForEmail"
                  select
                  helperText={touched.askForEmail && errors.askForEmail}
                  error={!!touched.askForEmail && !!errors.askForEmail}
                  sx={{ gridColumn: "span 1" }}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Activate Demo Site
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ConfigurationList;
