import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import StatBox from '../../components/StatBox';
import ConfigurationList from '../../components/ConfigurationList';

import axios from "axios";

const Lead = () => {
  let leadId = localStorage.getItem('leadId');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [leadData, setLead] = useState([]);
  const [demoConfig, setDemoConfig] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    axios.post('https://ebcrmportal.com/api/leads/details', {leadId: leadId})
    .then(response => {
      setLead(response.data.data.info);
      setDemoConfig(response.data.data.demoSettings);
    })
  }, [leadId])

  const goTo = (route) => {
    localStorage.removeItem('leadId');
    navigate(route);
  }

  return (
    <Box m="20px">
      <Header title="Lead Details" subtitle="Lead Profile Page" />

      <Box m="0 0 20px 0">
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
          onClick={() => goTo('/leads')}
        >
          Return to List of Leads
        </Button>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <StatBox
                title={leadData.company}
                subtitle={ leadData.name }
                progress={false}
            />
        </Box>

        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <StatBox
                title={leadData.address}
                subtitle={ leadData.city + ' ' + leadData.state + ' ' + leadData.zipCode }
                progress={false}
            />
        </Box>

        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <StatBox
                title={leadData.phone}
                subtitle={ leadData.email }
                progress={false}
            />
        </Box>

        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <StatBox
                title={leadData.type}
                subtitle={ leadData.templateId }
                progress={false}
            />
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="140px"
        gap="20px"
        m="20px"
      >
        <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          {
            Object.keys(demoConfig).length !== 0 &&
              <ConfigurationList title="Demo Site Configuration" leadData={leadData} configuration={demoConfig} />
          }
        </Box>
      </Box>


    </Box>
  );
};

export default Lead;
